<template>
    <!-- <NavBar /> -->
    <div class="pageBody">
        <div class="router">
            <router-view />
        </div>
        <div class="version">
            {{ projectVersion }}
        </div>
    </div>
    
</template>

<script>
import NavBar from '@/components/navbar/NavBar.vue';
import { projectVersion } from "@/main.js";

export default {
    components: {
        NavBar
    },
    data () {
        return {
            projectVersion: projectVersion
        }
    }
}
</script>

<style scoped>
.pageBody {
    background: linear-gradient(0deg, rgb(43 0 101) 0%, rgba(100,134,255,1) 100%);
}

.background-ball {
    top:52px;
    position: absolute;
    background-color: var(--var-primary-color);
    width: 500px;
    height: 500px;
    z-index: 0;
    filter: blur(300px);
    /* opacity: ; */
}

.router, .container {
    position: relative;
    z-index: 1;
}

.version {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;
    color: var(--text-primary);
    padding: 5px 10px;
    opacity: 0.3;
    font-size: 12px;
}
</style>