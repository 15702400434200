<template>
    <div class="cardDeck-outer">
        <h2>{{ header }} <span>{{ length }}</span></h2>
        <div class="cardDeck">
            <Card 
                v-for="[idx, card] in cards.entries()"
                :key="idx"
                :card="card"
                :traitsList="traitsList"
            />
        </div>
        <div class="cardDeck-background"></div>
    </div>
    
</template>

<script>
import Card from './cards/Card.vue';
export default {
    components: {
        Card
    },
    props: {
        cards: {
            default: () => []
        },
        header: {
            default: ""
        },
        traitsList: { default: () => [] }
    },
    computed: {
        length () {
            if (this.cards)
                return this.cards.length
        }
    }
}
</script>

<style scoped>
.cardDeck {
    position: relative;
    /* background-color: var(--var-tertiary-color); */
    height: 320px;
    border-radius: 15px;
    width: auto;
    padding: 30px 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    /* filter: blur(20px); */
}

.cardDeck-outer {
    position: relative;
    border-radius: 15px;
    /* background-color: red; */
    overflow: hidden;
}

.cardDeck-background {
    position: absolute;
    top:48px;
     left: 0;
    background-color: var(--var-quaternary-color);
    height: 320px;
    border-radius: 15px;
    width: 100%;
    padding: 20px 10px;
    /* filter: blur(10px); */
    backdrop-filter: blur(7.1px);
-webkit-backdrop-filter: blur(7.1px);
    opacity: 0.3;
    z-index: -1;
}

h2 {
    color: var(--var-aux-color);
    text-align: left;
}

h2 > span {
    display: inline-block;
    vertical-align: text-bottom;
    background-color: var(--var-primary-color);
    padding: 5px 11px 5px 11px;
    font-size: 18px;
    border-radius: 50%
}
</style>