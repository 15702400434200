<template>
    <div class="localizationMenu">
        <div v-for="(entry, index) in filteredLanguages" :key="entry.title">
            <button
                :class="{currentLocale:currentLocale == entry.language}"
                @click="changeLocale(index + 1)"
            >
                <div class="center-absolute">
                    <country-flag 
                        :rounded="true"
                        :country='entry.flag' 
                        size='big'
                    />
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import { i18n } from '@/plugins/i18n';

export default {
    props: {
        selectedLanguage: {
            type: String
        }
    },
    data () {
        return {
            languages: [
                { flag: 'us', language: 'en', title: 'English' },
                { flag: 'br', language: 'pt', title: 'Português' }
            ]
        }
    },
    computed: {
        filteredLanguages () {
            let idx = this.languages.map((el) => el.language).indexOf(this.currentLocale);
            return this.reorder(this.languages, idx)
        },
        currentLocale () {
            return i18n.global.locale
        },
    },
    methods: {
        changeLocale(index) {
            let idx = this.filteredLanguages[index] ? index : 0
            i18n.global.locale = this.filteredLanguages[idx].language;
            this.$emit('update:selectedLanguage', this.filteredLanguages[0].title);
        },
        reorder(data, index) {
            return data.slice(index).concat(data.slice(0, index))
        },
        toggleDarkMode () {
            this.$store.commit('toggleDarkMode')
        }
    },
    mounted() {
        this.$emit('update:selectedLanguage', this.filteredLanguages[0].title);
    }
}
</script>

<style scoped>
    .localizationMenu {
        /* position: absolute; */
        /* top: 0px; */
        height: 35px;
        overflow: hidden;
        /* right: 10px; */
        /* z-index: 100; */
    }
    button {
        position: relative;
        opacity: 0.2;
        /* padding: 12px 5px; */

        overflow: hidden;
        border-radius: 50%;
        width: 30px;
        height: 30px;

        appearance: none;
        background-color: transparent;
        border: none;

        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    }
    button:disabled {
        opacity: 0.4;
    }
    button.flag {
        opacity: 0.3;
    }
    button:hover {
        opacity: 1;
        -webkit-filter: grayscale(0);
        filter: none;
    }
</style>