<template>
    <div class="header mb-5">
        <div class="header-content">
            <img :src="require(`@/assets/jpg/setup.jpg`)"  alt="">
            <div class="center-absolute">
                <h1>Dynamic CV</h1>
                <p>Click on the cards for more details</p>
            </div>
            
            <div class="overlay"></div>
        </div>
        <div class="container">
            <div class="header-bar-outer">
                <div class="header-bar">
                    <StatisticsBar/>
                </div>
            </div>
        </div>
    </div>
    <div class="container container-fix">
        <div>
            <div class="background-ball-l"></div>
            <CardDeck 
                class="mb-3 "
                :cards=resumeData.experiences
                :traitsList="resumeData.skills"
                header="Experience"
            />
            <div class="background-ball-r"></div>
            <CardDeck
                class="mb-3"
                :cards="resumeData.projects"
                :traitsList="resumeData.skills"
                header="Projects"
            />
            <div class="background-ball-l"></div>
            <CardDeck
                class="mb-3"
                :cards="resumeData.academic"
                header="Academic"
            />
            <div class="background-ball-r"></div>
            <CardDeck
                class="mb-3"
                header="Specialization"
                :cards="resumeData.specializations"
            />
            <div class="background-ball-l"></div>
            <CardDeck
                class="mb-3"
                header="Courses"
                :cards="resumeData.courses"
            />
            
        </div>
    </div>
</template>

<script>
import CardDeck from '@/components/cardDeck/CardDeck.vue';
import StatisticsBar from '@/components/ui/statisticsBar/StatisticsBar.vue';
import resumeData from '@/data/cvs/en_us_gustavo_penedo.json'
export default {
    components: {
        CardDeck,
        StatisticsBar
    },
    data () {
        return {
            resumeData: resumeData
        }
    }
}
</script>

<style scoped>
.header {
    /* background-color: var(--var-secondary-color); */
    margin-bottom: 75px !important;
}

.header-content {
    height: 280px;
    overflow: hidden;
    position: relative;
    /* border-bottom: 20px solid var(--var-primary-color-light4); */
}

.header-content h1 {
    font-size: 3em;
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 10px black;
}

.header-content p {
    color: var(--var-aux-color);
    text-shadow: 0px 2px 10px black;
}

.header-content img {
    width: 100%;
    margin-top: -100px;
    opacity: 0.5;
    filter: blur(3px)
}

.header-content .overlay {
    width: 100%;
    /* margin-left: -20vw; */
    height: 100%;
    position: absolute;
    bottom: 0;
    /* filter: blur(10px); */
    z-index: 0;
    /* background: linear-gradient(0deg, rgba(47,47,47,1) 0, rgba(0, 0, 0, 0) 11%); */
}


.header-bar-outer {
    margin-top: -78px;
    padding-top: 50px;
    /* height: 75px; */
    /* position: absolute; */
}
.header-bar {
    background-color: var(--var-primary-color);
    /* height: 50px; */
    border-radius: 15px;
    position: relative;
    /* position: absolute; */
    /* box-shadow: 0 0 5px #555; */
    /* backdrop-filter: blur(7.1px); */
    /* -webkit-backdrop-filter: blur(7.1px); */
}

.container-fix {
    margin-top: -30px;
}

/* .background-ball-l, .background-ball-r{
    
    position: absolute;
    z-index: -2;
    width: 400px;
    height: 400px;
    background-color: var(--var-primary-color);
    filter: blur(250px); 
}

.background-ball-r {right: 100px}
.background-ball-l {left: 100px} */
</style>