import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import CountryFlag from 'vue-country-flag-next';
import FontAwesomeIcon from '@/plugins/fa.config.js';
import { i18n } from '@/plugins/i18n';
import VueGtag from "vue-gtag";
import "./plugins/registerServiceWorker";



const projectVersion = process.env.VUE_APP_VERSION
const projectEnvironment = process.env.VUE_APP_ENVIRONMENT || null 

const gTagConfig = {
    config: { id: "G-R700KNFJ2T" }
}

createApp(App).use(store)
              .use(router)
              .use(i18n)
              .component('country-flag', CountryFlag)
              .component('font-awesome-icon', FontAwesomeIcon)
              .use(VueGtag, gTagConfig)
              .mount("#app");

export {
    projectVersion,
    projectEnvironment
}