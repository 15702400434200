<template>
    <div class="navbar-outer">
        <div class="navbar" :class="{container:profile}">
            <div class="container">
                <div class="row w-100 p-0 m-0">
                    <div class="col-6 p-0 left-container">
                        <div class="brand">
                            <p class="noselect">Gustavo Penedo</p>
                        </div>
                        
                    </div>
                    <div class="col-6 right-container p-0">
                        <div class="menu" @click="toggleMobileMenu">
                            <font-awesome-icon icon="bars"/>
                        </div>                  
                        <div class="dropMenu" :class="{active: mobileMenu}">
                            <div>
                                <button class="actionButton" @click="$router.push({name: 'home'})">
                                    <font-awesome-icon :icon="['fas', 'house']" />
                                </button>
                                <button class="actionButton" @click="$router.push({name: 'resume'})">
                                    CV
                                </button>
                                <button class="actionButton borderRight" @click="$router.push({name: 'vision'})">
                                    Vision
                                </button>
                                <!-- <button class="actionButton borderRight">
                                    Gallery
                                </button>
                                <div class=" borderRight"></div> -->
                                <button
                                    class="actionButton icon"
                                    :title="urls.linkedin"
                                    @click="newWindow(urls.linkedin)"
                                >
                                    <font-awesome-icon :icon="['fab', 'linkedin']" />
                                </button>
                                <button 
                                    class="actionButton icon"
                                    :title="urls.email"
                                    @click="newWindow(`mailto:${urls.email}`)"
                                >
                                    <font-awesome-icon :icon="['fas', 'envelope']" />
                                </button>
                                
                            </div>
                            
                            
                            
                            <!-- <button class="actionButton">
                                <font-awesome-icon icon="question-circle" />
                            </button> -->
                        </div>               
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
// import SearchBox from "@/components/input/SearchBox.vue";
export default {
    components: {
        // SearchBox
    },
    props: {
        profile: {default: false}
    },
    data () {
        return {
            mobileMenu: false,
            searchActive: false,
            urls: {
                email: 'gustavo-penedo@hotmail.com',
                linkedin: 'https://www.linkedin.com/in/gustavopenedo/'
            }
        }
    },
    computed: {
        // user () {
        //     return this.$store.getters.user
        // },
        // isAuthentificated () {
        //     return !(Object.entries(this.user).length === 0 &&
        //         this.user.constructor === Object)
        // }
    },
    methods: {
        toggleMobileMenu () {
            this.$store.commit('toggleMobileMenu')
        },
        logout () {
            this.$store.dispatch('logout')
        },
        goToURL (url) {
            this.$router.go(url);
        },
        newWindow(url) {
            window.open(url)
        }
    }
    
}
</script>

<style scoped>
    @import '@/assets/css/navbar/navbar.css';
</style>
