import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faPowerOff, faBars, faSearch, faBullhorn, faHome, faCaretDown, faDollarSign, faCog, faTruck, faBoxOpen, faUsers, faCalendarAlt, faPlus, faTruckLoading, faBell, faExpand, faDownload, faCookieBite, faCalendarDay, faParachuteBox, faUserEdit, faUserPlus, faPencilAlt, faPlusSquare, faTimesCircle, faSort, faSortDown, faSortUp, faSave, faScroll, faUser, faBox, faTrashAlt, faUpload, faWarehouse, faEllipsisV, faSync, faCheck, faTimes, faCity, faLaughBeam, faClipboardCheck, faExclamationCircle, faCheckCircle, faFileInvoiceDollar, faBed, faShower, faCar, faPaw, faSnowflake, faBolt, faSwimmingPool, faUserShield, faCouch, faCaretSquareUp, faBuilding, faMobile, faEllipsisH, faWindowMinimize, faCheckSquare, faSmileWink, faQuestionCircle, faThumbtack, faCamera, faFrown, faImages, faStar, faHandHoldingUsd, faPenAlt, faFileContract, faMapMarkerAlt, faHandsHelping, faPortrait, faBook, faTachometerAlt, faClock, faTh, faTable, faCaretRight, faThLarge, faBriefcase, faAddressCard, faPrint, faArrowLeft, faUserTie, faHammer, faUserMd, faInfinity, faChild, faBrain, faFileAlt, faUserLock, faCreditCard, faFileSignature, faInfo, faExternalLinkAlt, faHandshake, faFilter, faCloudMoon, faCloudSun, faComment, faExclamationTriangle, faImage, faEnvelope, faWheatAwn, faPlaneUp, faSuitcaseMedical, faGraduationCap, faComputer, faIndustry, faHouse, faCode, faDiagramProject, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngular, faAws, faCcAmex, faCcDiscover, faCcMastercard, faCcVisa, faGithub, faJava, faLinkedin, faPhp, faPython, faReact, faVuejs, faWordpress } from '@fortawesome/free-brands-svg-icons';

library.add(faCoffee,
  faPowerOff,
  faBars,
  faSearch,
  faBullhorn,
  faHome,
  faCaretDown,
  faCaretRight,
  faDollarSign,
  faCog,
  faTruck,
  faBoxOpen,
  faUsers,
  faCalendarAlt,
  faPlus,
  faTruckLoading,
  faBell,
  faExpand,
  faDownload,
  faCookieBite,
  faCalendarDay,
  faParachuteBox,
  faUser,
  faUserEdit,
  faUserPlus,
  faPencilAlt,
  faPlusSquare,
  faTimes,
  faTimesCircle,
  faSort,
  faSortDown,
  faSortUp,
  faSave,
  faScroll,
  faBox,
  faTrashAlt,
  faUpload,
  faWarehouse,
  faCoffee,
  faEllipsisV,
  faEllipsisH,
  faSync,
  faCheck,
  faCity,
  faLaughBeam,
  faClipboardCheck,
  faExclamationCircle,
  faCheckCircle,
  faFileInvoiceDollar,
  faBed,
  faShower,
  faCar,
  faPaw,
  faSnowflake,
  faBolt,
  faSwimmingPool,
  faUserShield,
  faCouch,
  faCaretSquareUp,
  faBuilding,
  faMobile,
  faWindowMinimize,
  faCheckSquare,
  faSmileWink,
  faQuestionCircle,
  faThumbtack,
  faCamera,
  faFrown,
  faImages,
  faStar,
  faHandHoldingUsd,
  faPenAlt,
  faFileContract,
  faMapMarkerAlt,
  faHandsHelping,
  faPortrait,
  faBook,
  faTachometerAlt,
  faClock,
  faTh,
  faThLarge,
  faTable,
  faBriefcase,
  faAddressCard,
  faPrint,
  faArrowLeft,
  faUserTie,
  faHammer,
  faUserMd,
  faInfinity,
  faChild,
  faBrain,
  faFileAlt,
  faCheck,
  faExclamationCircle,
  faUserLock,
  faCreditCard,
  faFileSignature,
  faInfo,
  faSquare,
  faExternalLinkAlt,
  faHandshake,
  faFilter,
  faCloudMoon,
  faCloudSun,
  faComment,
  faExclamationTriangle,
  faImage,
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faCcDiscover,

  faLinkedin,
  faEnvelope,
  faClock,
  faPython,
  faVuejs,
  faJava,
  faAws,
  faPhp,
  faWordpress,
  faReact,
  faAngular,
  faWheatAwn,
  faPlaneUp,
  faSuitcaseMedical,
  faGraduationCap,
  faComputer,
  faIndustry,
  faHouse,
  faCode,
  faComment,
  faUser,
  faDiagramProject,
  faFileArrowDown,
  faGithub
)

export default FontAwesomeIcon
