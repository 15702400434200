<template>
    <PageView />
</template>

<script>
    import PageView from './views/PageView.vue';
    export default {
        components: {
          PageView
        },
        methods: {
            handleResize () {
                this.$store.commit('setStatusMobile', window.innerWidth <= 768);
                this.$store.commit('setStatusSmallScreen', window.innerWidth <= 1080) 
            }
        },
        created () {
            window.addEventListener('resize', this.handleResize)
            this.handleResize()
        },
        destroyed () {
            window.removeEventListener('resize', this.handleResize)
        },
    }
</script>

<style>
    @import '@/assets/css/global/app.css';
</style>
