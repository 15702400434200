export default {
    tags: [
        {
            name: "Python",
            amount: "8",
            tag: "generic.years",
        },
        {
            name: null,
            amount: "2",
            tag: "generic.specializations",
        },
        {
            name: null,
            amount: null,
            tag: "skills.management"
        }
    ],
    cards: [
        {
            key: "resume",
            button: {
                label: "buttons.resume.label",
                redirectTo: "https://drive.google.com/file/d/18jqL4tqT7tOu-gWj96UeNO0bXA2kME7O/view"
            },
            icon: ['fas', 'file-arrow-down'],
        },
        {
            key: "linkedin",
            button: {
                label: "buttons.linkedin.label",
                redirectTo: 'https://www.linkedin.com/in/gustavopenedo/'
            },
            icon: ['fab', 'linkedin']
        },
        {
            key: "email",
            button: {
                label: "buttons.email.label",
                redirectTo: "mailto:gustavo-penedo@hotmail.com"
            },
            icon: ['fas', 'envelope'],
        }
    ]
}