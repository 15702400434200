export default {
    buttons: {
        email: {
            description: "Quer entrar em contato, me mande uma mensagem.",
            label: "Enviar e-mail",
        },
        linkedin: {
            description: "Para mais detalhes, visite meu perfil.",
            label: "Ir para o LinkedIn",
        },
        resume: {
            description: "Para informações resumidas, acesse meu currículo.",
            label: "Baixar CV",
        },
        moreDetails: {
            label: "Mais Detalhes",
        }
    },
    cards: {
        projects: {
            itMerging: {
                title: "Fusão de TI",
                text: "Fusão de aplicações e bancos de dados envolvendo duas grandes empresas. Gerenciamento de equipe.",
            },
            jobai: {
                title: "Recomendador de Empregos",
                text: "Sistema de recomendação baseado no currículo do candidato e nos requisitos da vaga de emprego.",
            },
            portfolio: {
                title: "Portfólio",
                text: "Aplicação web estilizada para exibir minhas habilidades e fornecer informações de contato.",
            }
        },
        specializations: {
            projectManagement: {
                title: "Gestão de projetos",
                text: "Emitido pela Coursera em parceria com o Google.",
            },
            deepLearning: {
                title: "Deep Learning",
                text: "Emitido pela Coursera em parceria com a DeepLearning.ai",
            },
            leadingPeople: {
                title: "Lidando com pessoas e equipes",
                text: "(EM ANDAMENTO) Emitido pela Coursera em parceria com a Universidade de Michigan.",
            }
        },
    },
    generic: {
        specializations: "especialização | especializações | {n} especializações",
        years: "ano | anos | {name}: {n} anos | {n} anos ",
    },
    profile: {
        name: "Gustavo Penedo",
        profession: "Engenheiro da Computação",
    },
    skills: {
        management: "Gerência de células",
    },
    views: {
        home: {
            title: "Home",
            description1: "Registro de trabalho internacional em empresas com ambientes multiculturais.",
            description2: "Exerço uma função crucial ao entregar soluções de alta qualidade focadas em WEB.",
        },
        projects: {
            title: "Projetos",
            subtitle: "Trabalhos que valem citar",
        },
        specializations: {
            title: "Especializações",
            subtitle: "Gosto de aprender",
        },
        settings: {
            title: "Configurações",
            subtitle: "Você pode ajustar algumas coisas"
        }
    },
}