<template>
    <div 
        class="vision-component-card vision-text-primary"
        :class="{
            clickable: button
        }"
        :style="{
            height: height
        }"
    >
        <div v-if="icon"
             class="vision-component-card-icon">
            <font-awesome-icon 
                :icon="icon" 
            />
        </div>
        <div 
            v-if="title"
            class="vision-component-card-title"
        >
            <h2>{{ title }}</h2>
        </div>
        
        <p  
            v-if="text"
            class=""
        >
            {{ text }}
        </p>
        <div class="row">
            <div 
                v-for="tag in tags"
                class="col-6 pb-2 pe-1 ps-1"
            >
                <VisionTag 
                    :text="tag.text"
                />
            </div>
            
        </div>
        <div 
            v-if="button"
            class="button-container"
        >
            <VisionButton
                :label="$t(button.label)"
                :icon="button.icon"
                :redirectTo="button.redirectTo"
            />
        </div>
        
    </div>
</template>

<script>
    import VisionButton from "@/components/vision/button/VisionButton.vue"
    import VisionTag from "@/components/vision/tag/VisionTag.vue"
    
    export default {
        components: {
            VisionButton,
            VisionTag
        },
        props: {
            title:{
                type: String,
                default: null
            },
            text: {
                type: String,
                default: null
            },
            button: {
                type: Object,
                default: null
            },
            icon: {
                type: Array,
                default: null
            },
            tags: {
                type: Array,
                default: null
            },
            height: {
                type: String,
                default: "200px"
            }
        },
    }
</script>

<style scoped>
    @import '@/assets/css/global/visionOS/components/visionComponentCard.css';
</style>