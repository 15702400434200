<template>
    <div
        class="vision-component-tooltip"
        :class="{
            top: position === 'top',
            right: position === 'right'
        }"
    >
        <span class="vision-text-primary">{{ label }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            position: {
                type: String,
                default: 'right'
            }
        }
    }
</script>

<style scoped>
    .vision-component-tooltip {
        /* display: none; */
        /* display: inline-block; */
        /* position: relative; */
        position: absolute;
        
        padding: 4px 12px;
        border-radius: 200px;
        /* background-color: red; */
        background: linear-gradient(0deg, rgba(128, 128, 128, 0.8) 0%, rgba(128, 128, 128, 0.8) 100%), rgba(255, 255, 255, 0.03);
        background-blend-mode: color-dodge, luminosity;
        backdrop-filter: blur(68px);
        /* z-index: 10; */
        opacity: 0;

        
        transition: 0.1s ease-in-out opacity;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    }
    .vision-component-tooltip.top {
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
    }
    .vision-component-tooltip.right {
        left: 70px;
    }
</style>