<template>
    <button
        class="vision-button"
    >
        <font-awesome-icon 
            :icon="icon" 
        />
        <span>{{ label }}</span>
    </button>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            icon: {
                type: Array,
            },
            redirectTo: {
                type: String
            }
        }
    }
</script>

<style scoped>
    .vision-button {
        display: inline-flex;
        height: 52px;
        padding: 0px 25px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
        border-radius: 500px;
        background: linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
        background-blend-mode: color-dodge, lighten;
        border: none;

        color: var(--text-primary);
        text-align: center;
        font-size: 19px;
        font-style: normal;

        transition: 0.3s ease-in-out background,  0.3s ease-in-out color;
    }
    .vision-button span {
        margin-left: 5px;
    }
    /* .vision-button:hover {
        background: radial-gradient(101.08% 100.00% at 50.00% 100.00%, rgba(94, 94, 94, 0.14) 0%, rgba(94, 94, 94, 0.00) 73.85%), radial-gradient(100.02% 100.00% at 50.00% 100.00%, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.00) 55.59%), linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06);
    } */
</style>