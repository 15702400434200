<template>
    <div>
        <div class="row p-4">
            <div 
                v-for="card in cards"
                class="col-12 col-md-4 pb-4"
            >
                <VisionCard
                    :text="$t(card.text)"
                    :title="$t(card.title)"
                    :tags="card.tags"
                    :button="card.button"
                    height="350px"
                    @click="card.button ? newWindow(card.button.redirectTo) : null"
                />
            </div>         
        </div>
    </div>
</template>

<script>
import specializationsInfo from "@/data/settings/visionViewSpecializationsConfig";
import VisionCard from "@/components/vision/card/VisionCard.vue";
import utilsMixin from "@/mixins/utilsMixin";

export default {
    components: {
        VisionCard
    },
    mixins: [utilsMixin],
    data () {
        return {
            cards: specializationsInfo
        }
    }
}
</script>

<style>

</style>