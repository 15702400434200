<template>
    <div class="loader-box">
        <div class="center-absolute">
            <span class="loader"></span>
        </div>
    </div>
    
</template>

<style scoped>
    .loader-box {
        position: relative;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, #2f2f2f 0%, rgba(94, 94, 94) 100%);
    }
    .loader {
        display: block;
        width: 48px;
        height: 48px;
        display: inline-block;
        position: relative;
    }
    .loader::after,
    .loader::before {
        content: '';  
        box-sizing: border-box;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 2px solid #FFF;
        position: absolute;
        left: 0;
        top: 0;
        animation: animloader 2s linear infinite;
    }
    .loader::after {
        animation-delay: 1s;
    }

    @keyframes animloader {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
}
</style>