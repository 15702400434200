export default [
    {
        title: "cards.specializations.projectManagement.title",
        text:  "cards.specializations.projectManagement.text",
        button: {
            icon: ['fab', 'linkedin'],
            label: "buttons.moreDetails.label",
            redirectTo: "https://www.linkedin.com/in/gustavopenedo/"
        },
        
    },
    {
        title: "cards.specializations.deepLearning.title",
        text:  "cards.specializations.deepLearning.text",
        button: {
            icon: ['fab', 'linkedin'],
            label: "buttons.moreDetails.label",
            redirectTo: "https://www.linkedin.com/in/gustavopenedo/"
        },
    },
    {
        title: "cards.specializations.leadingPeople.title",
        text:  "cards.specializations.leadingPeople.text",
    }
]