export default {
    background: "https://i.ibb.co/jkL5NkV/living-Room-compressed.jpg",
    items: [
        {
            key: 'visionHome',
            icon: ['fas', 'house'], 
            label: 'views.home.title', 
            title: "profile.name",
            subtitle: "profile.profession"
        },
        {
            key: 'visionProjects',
            icon: ['fas', 'diagram-project'],
            label: 'views.projects.title',
            title: 'views.projects.title',
            subtitle: 'views.projects.subtitle',
        },
        {
            key: 'visionSpecialization',
            icon: ['fas', 'graduation-cap'],
            label: 'views.specializations.title',
            title: 'views.specializations.title',
            subtitle: 'views.specializations.subtitle',
        },
        // {   
        //     key: 'visionSettings', 
        //     icon: ['fas', 'cog'], 
        //     label: 'views.settings.title',
        //     title: 'views.settings.title',
        //     subtitle: 'views.settings.subtitle',
        // },
    ]
}