import { createI18n } from 'vue-i18n'

import en from '@/data/locales/en'
import pt from '@/data/locales/pt'

const messages = {
    'en': en,
    'pt': pt
};

const i18n = new createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  globalInjection: true,
  messages, // set locale messages
})

export {
    i18n
};