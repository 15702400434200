<template>
    <div class="row statisticsBar">
        <div v-for="[idx, el] in columns.entries()"
             :key="idx"
             :class="colClass"
             :title="el.name"
        >
            <div class="statisticsBar-col">
                <div>
                    <font-awesome-icon 
                        :icon="el.icon" 
                    />
                </div>
                
                <span v-if="el.span">{{el.span}}</span>
                <span 
                    v-else-if="el.spanIcons"
                    class="statisticsBar-col-spanIcons"
                >
                    <div 
                        v-for="i in el.spanIcons"
                        :key="i.name"
                        :title="i.name"
                        class="c-flag"
                    >
                        <country-flag 
                            v-if="i.type === 'flag'"
                            :rounded="true"
                            :country='i.icon' 
                            size='normal'
                        />
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                columns: [
                    {name: 'Profession', span: 'Software Engineer', icon: ["fa-solid", "fa-graduation-cap"]},
                    {name: 'Main Programming Language', span: 'Python (6 years)', icon: ["fa-solid", "fa-code"]},
                    {name: 'Longest Industry Experience', span: 'Aviation (3 years)', icon: ["fa-solid", "fa-industry"]},
                    {
                        name: 'Spoken Languages',
                        icon: ["fa-solid", "fa-comment"],
                        spanIcons: [
                            {name: "English (US)", icon:'us', type:'flag'},
                            {name: "Portuguese (BR)", icon:'br', type:'flag'}
                        ]
                    },
                ],
                colClass: "col-6 col-sm-6 col-md-6 col-lg-3"
            }
        }
    }
</script>

<style scoped>
    .statisticsBar {
        padding: 5px 15px;
    }
    .statisticsBar > div {
        padding: 0px;
        /* background-color: red; */

    }

    .statisticsBar-col {
        position: relative;
        padding: 10px;
        /* background: var(--var-primary-color-light4); */
        
        /* border-radius: 15px;
        border: 5px solid var(--var-secondary-color); */
        /* transition: background-color 0.1s ease-in-out; */
    }
    /* .statisticsBar-col:hover {
        background-color: var(--var-quaternary-color);;
    } */
    div.statisticsBar-col::after{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);;
        right: 0;
        display: inline-block;
        border-right: 1px solid white;
        width: 1px;
        height: 30px;
        content: "";
    }

    .statisticsBar-col-spanIcons {
        margin-left: -8px;
        
    }

    .statisticsBar-col-spanIcons > div {
        transform: scale(0.85)
    }   

    div:last-child > .statisticsBar-col::after {
        content: none;
    }

    div:last-child > .statisticsBar-col {
        border-radius: 0px 15px 15px 0;
    }

    .statisticsBar-col div {
        display: inline-block;
        margin: 0;
        color: white;
        margin-right: 15px;
    }

    .statisticsBar-col span {
        color: white;
        /* color: var(--var-primary-color); */
        font-weight: bold;
    }

    .c-flag {
        position: relative;
        height: 16px;
        width: 30px;
    }
    .c-flag span {
        position: absolute;
        top: -9px
    }


</style>