<template>
    <div class="vision-component-tag">
        <p class="">{{ text }}</p>        
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
    .vision-component-tag {
        position: relative;
        padding: 5px 20px;
        width: 100%;
        height: 40px;
        border-radius: 16px;
        background: var(--views-darker, linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), rgba(214, 214, 214, 0.2));
        background-blend-mode: luminosity, color-burn;
        opacity: 0.9;
        
    }

    .vision-component-tag p {
        margin: 0;
        margin-top: 4px;
        display: inline-block;
        vertical-align: middle
    }
</style>