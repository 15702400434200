const state = {
    statusMobile: false,
    statusSmallScreen: false
}

const mutations = {
    setStatusMobile (state, status) {
        state.statusMobile = status
    },
    setStatusSmallScreen (state, status) {
        state.statusSmallScreen = status
    },
}

const getters = {
    statusMobile (state) {
        return state.statusMobile
    },
    statusSmallScreen (state) {
        return state.statusSmallScreen
    },
    
}

export default {
    state,
    mutations,
    getters
}