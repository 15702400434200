import { createStore } from "vuex";

import config from '@/store/modules/config';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        config
    },
});
