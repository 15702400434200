<template>
    <div class="card-outer">
        <div class="card">
            <div class="card-image">
                <div class="card-tags">
                    <div
                        v-if="duration" 
                        class="card-tag"
                    >
                        <font-awesome-icon 
                            :icon="['fas', 'clock']" 
                        />
                        <span class="ms-2"> {{duration}}</span>
                    </div>
                </div>
                <div
                    v-if="card.icon || card.iconImage" 
                    class="card-icon"
                >
                    <font-awesome-icon 
                        v-if="card.icon"
                        class="center-absolute"
                        :icon="card.icon" 
                    />
                    <div v-else-if="card.iconImage"
                        class="card-icon-image center-absolute"
                    >
                        <img 
                            :src="require(`@/assets/${card.iconImage}`)" 
                            alt=""
                        />
                    </div>
                    <p v-if="card.iconTitle"
                        class="card-icon-title center-absolute">
                        <font-awesome-icon 
                            :icon="card.iconTitleIcon"
                        /> 
                        <span class="ms-2">
                            {{ card.iconTitle }}
                        </span>
                    </p>
                </div>
                
                <!-- <img v-if="card.images && card.images.length > 0" :src="card.images[0].url" alt=""> -->
            </div>
            <div class="card-content">
                <div
                    v-if="traits"
                    class="card-traits"
                >
                    <div 
                        v-for="[idx, skill] in traits.entries()"
                        :key="idx"
                        :title="skill.name"
                        class="card-trait"
                    >
                        <font-awesome-icon
                            v-if="skill.icon"
                            :icon=skill.icon 
                        />
                        <p v-else>{{ skill.iconText }}</p>
                    </div>
                </div>
                <div 
                    v-else-if="card.traitTitle"
                    class="card-traits"
                >
                    <div class="card-trait">
                        <p>{{ card.traitTitle }}</p>
                    </div>
                </div>
                <div class="card-body">
                    <h6>{{card.name}}</h6>
                    <p class="card-subtitle">
                        {{ card.subtitle }}
                    </p>
                    <!-- <div class="left-container">
                        <p class="area">{{card.name}}</p>
                        <p class="type">{{card.property_type}}</p>
                        <p class="area">{{`${card.area}m`}}<sup>2</sup></p>
                        
                    </div> -->
                    
                    <div class="right-container">
                        <!-- <p class="street">Test</p> -->
                        <!-- <p class="city">{{card.address.neighbourhood}}</p> -->
                        <!-- <p class="city" v-else ><span class="mini-nei">{{card.address.neighbourhood}}</span>
                            {{`${card.address.city}, ${card.address.state}`}}
                        </p> -->
                        <!-- <p class="city">{{`${card.address.city}, ${card.address.state}`}}</p>
                        -->
                    </div>
                    
                    <!-- <div class="bottom-container">
                        <p class="rent-price"
                        v-if="card.rentprice && card.rentprice > 0 && interest == 'rent'">
                       :class="{only:!(card.rentprice && card.rentprice > 0)}">
                            {{`R$ ${card.rentprice.toLocaleString('pt-BR')}`}} <span>/mês</span>
                        </p>
                        <div class="rent-price" v-else>
                            {{`R$ ${card.sellprice.toLocaleString('pt-BR')}`}}
                        </div>
                        <p class="sell-price"
                        v-if="card.sellprice && card.sellprice > 0 && interest == 'rent'
                                && card.rentprice"
                        :class="{only:!(card.rentprice && card.rentprice > 0)}">
                        <span class="currency"> R$ </span> {{moneyFormatter(card.sellprice)}}
                        </p>
                        <p class="sell-price"
                        v-else-if="card.rentprice && card.rentprice > 0 && card.sellprice">
                        <span class="currency"> R$ </span>
                        {{`${card.rentprice.toLocaleString('pt-BR')}`}} <span>/mês</span>
                        </p>
                        <div v-else class="sell-price only-warning">
                            <div>Somente Venda</div>
                        </div>
                    </div> -->
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            images: []
        }
    },
    props: {
        card: {default: {}},
        city: {default: false},
        traitsList: { default: () => [] }
    },
    computed: {
        traits () {
            if (this.card.traits && this.traitsList)
                return this.traitsList.filter(el => this.card.traits.includes(el.code))
        },
        interest () {
            var interest = this.$store.getters.getFilters.interest
            return interest? interest : null
        },
        duration () {
            if (this.card.end && this.card.begin) {
                let end = this.card.end
                if (end === '__TODAY__')
                    end = new Date();
                else {
                    end = end.split('-');
                    end = new Date(end);
                }
                let begin = this.card.begin.split('-');
                begin = new Date(begin)

                const diffTime = Math.abs(end - begin);
                const diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30)); 
                
                if (diff > 11)
                    return (diff / 12).toFixed(0) + " years"
                return diff + " months"
            }
            else if (this.card.duration)
                return this.card.duration
            
        }
    },
    methods: {
        openProperty () {
            this.$store.commit('openPropertyModal', this.card)
        },
        streetFormatter(string){
            string = string.split(',')
            if(string instanceof Array)
                return string[0]
            else return string
        },
        moneyFormatter(num) {
            var result = ''
            if(num > 1999999)
                result = Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + ' milhões'
            else if(num > 999999)
                result = Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + ' milhão'                
            else if(Math.abs(num) > 999)
                result = Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'mil'
            else
                result = Math.sign(num)*Math.abs(num)
            return result
        }
    },
    mounted() {
        // console.log(this.card)
        
    }
}
</script>


<style scoped>
    .card-outer {
        display: inline-block;
        vertical-align: top;
        padding: 0 15px;
        width: 250px;
        height: 250px;
        
    }
    .card {
        height: 250px;
        /* box-shadow: 0 0 5px #00000060; */
        background-color: transparent;
        border: none;
        /* border: 5px solid var(--var-secondary-color); */
        border-radius: 15px;
        margin-right: 0 !important;
        overflow: hidden;
    }
    .card:hover {
        /* position: absolute; */
        /* margin-top: -13px; */
        /* margin-left: -1px; */
        /* margin-bottom: 15px; */
        transform: scale(1.05);
        transition: transform 0.1s ease-in-out;
        cursor: pointer;
        z-index: 1;
        /* -webkit-transform: rotateX(25deg);
        transform: rotateX(25deg); */
        /* transform: translateZ(10); */
        -moz-box-shadow: 0 5px 5px #00000060;
        -webkit-box-shadow: 0 5px 5px #00000060;
        box-shadow: 0 0px 5px #00000030;
    }
    .card:hover .card-realstate{
        /* position: absolute; */
        
        /* z-index: -100;
        margin-top: 2px; */
        /* transform: rotateX(-25deg); */
    }
    .card-image {
        height: 240px;
        width: 100%;
    }
    .card-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius:5px 5px 0 0;
    }

    .card-traits {
        /* position: relative; */
        /* text-align: left; */
        position: absolute;
        z-index:2;
        top: -37px;
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        background-color: transparent;
        /* border-bottom: 1px solid #eee; */
        opacity: 0.7;
        
    }
    .card-traits svg {
        vertical-align: mid;
        color:var(--var-aux-color); 
        font-size:20px
    }
    .card-traits * {display: inline}
    .card-traits div{padding: 0 5px}
    .card-traits:first div{padding-left: 0; padding-right: 2px;}
    .card-traits p{
        vertical-align: text-bottom;
        /* background: var(--var-secondary-color); */
        color: white;
        /* border-radius: 50%; */
        /* margin-top: 2px; */
        /* padding: 10px 0; */
        font-size: 16px;
        font-weight: bold;
        /* margin-right: 5px; */
    }
    .separator {border-right: 1px solid #efefef; margin-right: 5px;}
    .card-body {
        position: relative;
        /* height: 80px; */
        padding: 5px 10px;
        z-index: 10;
        background-color: var(--var-primary-color-aux-dark);
    }
    

    .left-container {
        display: block;
        color: #999;
        float: left;
        text-align: left;
        font-size: 0.8em
    }
    .left-container .area{
        font-size: 1em;
        font-weight: bold;
        color: var(--var-primary-color)
    }

    .card-body p{margin: 0;}
    .card-body .right-container{
        /* float: right; */
        display: block;
        right: 0;
        text-align: right;
        color: var(--var-secondary-color)
    }

    .right-container .street {
        font-size: 0.8em;
    }
    .right-container .city {
        font-size: 0.9em;
        font-weight: bold;
    }

    /* .city .mini-nei {
         font-size: 0.8em;
    } */

    .bottom-container {display: block; height: 33.333px;}
    .bottom-container > * {
        display: inline-block;
    }
    .bottom-container .rent-price {
        width: 60%;
        text-align: left;
        /* padding-top: 2px; */
        font-size: 1.4em;
        color: #54564f;
        font-weight: bold;
    }

    .bottom-container .sell-price {
        width: 40%;
        text-align: right;
        /* padding-top: 5px; */
        font-size: 1.1em;
        font-weight: bold;
        color: #2b2c2890;
    }

    .bottom-container .rent-price.only, .bottom-container .sell-price.only {
        /* width: 100%; */

    }

    .bottom-container .only-warning div {
        vertical-align: middle;
        font-size: .6em;
        padding: 2px 10px;
        /* background-color: #6b6b6b; */
        color: white;
        border-radius: 5px;
        /* color: #6b6b6b; */
    }
    .bottom-container .currency {margin:0;}
    .bottom-container span {
        color: #2b2c2890;
        font-size: 14px;
        margin-left: -6px;
    }
    .rent-price.only-warning div { background-color: #6b6b6b; }
    .sell-price.only-warning div { background-color: #28a745; font-size: .7em;}
    /* .bottom-container .sell-price span{color: #28a745} */

    .card-realstate {
        position: absolute;
        width: 100%;
        height: 5px;
        background-color:var(--var-primary-color);
        /* margin-top:-30px; */
        z-index: 0;
        /* padding: 4px 10px 5px 3px; */
        transition: margin 0.1s ease-in-out;
        border-radius: 0 0 5px 5px;
        text-align: right;
        /* -moz-box-shadow: inset 0 15px 5px -10px #00000030;
        -webkit-box-shadow: inset 0 15px 5px -10px #00000030;
        box-shadow: inset 0 15px 5px -10px #00000030; */
    }
    .card-realstate img{
        height: 100%;
        /* height: auto; */
        /* object-fit: cover; */
        
    }

    /*-------------------------------------*/
    h6 {
        font-weight: bold;
        font-size: 15px;
        margin:0;
        color: var(--var-primary-color);
    }
    p {
        color: white;
        margin: 0;
    }

    .card-tags {
        top:0;
        position: absolute;
        /* text-align: right; */
        padding: 10px;
        width: 100%;
        opacity: 0.9;
        z-index: 3;
    }
    .card-tag {
        display: inline-block;
        padding: 5px 10px;
        background-color: var(--var-primary-color-light3);
        color: white;
        border-radius: 10px;
        font-size: 0.9em;
    }

    .card-image .card-icon {
        top: 0;
        position: relative;
        height: 100%;
        width: 100%;
        color: var(--var-primary-color-light2);
        background: rgb(47,47,47);
        background: linear-gradient(0deg, rgb(30, 2, 67) 0%, rgba(100,134,255,1) 100%);
        z-index: 2;
    }

    .card-content {
        position: relative;
    }

    .card-image .card-icon .card-icon-title {
        position: absolute;
        background-color: var(--var-primary-color-light3);
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 12px !important;
        transition: margin-top 0.1s ease-in-out,
                    opacity 0.1s ease-in-out;
        opacity: 0
    }

    .card:hover .card-icon-title{
        margin-top: 35px;
        opacity: 1;
        
    }

    .card-image .card-icon > svg, .card-image .card-icon-image > img {
        font-size:4em;
        z-index: 1;
        transition: margin-top 0.1s ease-in-out,
    }
    .card-image .card-icon-image {
        /* padding: 20px; */
    }
    .card-image .card-icon-image > img {
        width: 100px;
        transition: width 0.1s ease-in-out
    }

    .card:hover .card-icon > svg{
        margin-top: -15px;
        z-index: 0;
    }

    .card:hover .card-icon-image > img {
        width: 110px;
    }


    /*-------------------------------------*/

    
    @media only screen and (max-width: 1024px) {
        /* .card-image {height: 120px} */
    }

    @media only screen and (max-width: 767px) {
        /* .card-realstate:hover {position: relative;} */
        .card-image {height: 180px}

        .card:hover {
            margin-top: -0;
            transform: unset;
            -webkit-transform: unset;
            transform: unset;
            -moz-box-shadow: unset;
            -webkit-box-shadow: unset;
            box-shadow: unset;
        }
        .card:hover .card-realstate{
            /* position: absolute; */
            
            z-index: 0;
            margin-top:-30px;
            /* transform: rotateX(-25deg); */
        }

        .card-realstate img {display: none}
    }

</style>

<style>
    @media only screen and (max-width: 1376px) {
        .deck {
            width: 95%
        }
    }
</style>

